// Copyright © 2022 Kaleido, Inc.
//
// SPDX-License-Identifier: Apache-2.0
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { RouteObject } from 'react-router-dom';
import { NAMESPACES_PATH } from '../../interfaces';
import { ActivityTimeline } from './views/Timeline';
import { ActivityEvents } from './views/Events';
import { ActivityOperations } from './views/Operations';
import { ActivityTransactions } from './views/Transactions';
import { TransactionDetails } from './views/TransactionDetails';

export const ActivityRoutes: RouteObject = {
  path: `${NAMESPACES_PATH}/:namespace/activity`,
  children: [
    {
      path: '',
      index: true,
      element: <ActivityTimeline />,
    },
    {
      path: 'events',
      element: <ActivityEvents />,
    },
    {
      path: 'transactions',
      element: <ActivityTransactions />,
    },
    {
      path: 'transactions/:txID',
      element: <TransactionDetails />,
    },
    {
      path: 'operations',
      element: <ActivityOperations />,
    },
  ],
};
