import FFLogoWithApotheka from '../assests/ff-logo-with-apotheka.png';
import FFLogo from '../assests/ff-logo-dashboard3.png';

export const HyperledgerFireFlyImageWithApotheka = ({
  width,
  height,
  style,
}) => (
  <img
    src={FFLogoWithApotheka}
    alt="logo main"
    itemprop="image"
    width={width}
    height={height}
    style={style}
  />
);

export const HyperledgerFireFlyImage = ({ width, height, style }) => (
  <img
    src={FFLogo}
    alt="logo main"
    itemprop="image"
    width={width}
    height={height}
    style={style}
  />
);
